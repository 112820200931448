<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <employee-picker v-model="employee" :clearable="!isRequired" />
  </td>
</template>

<script>
import { getService } from '@/helpers/feathers'
import { Connection } from '@/offline/connection.enum'
import EmployeePicker from '@/components/EmployeePicker/EmployeePicker.vue'

export default {
  name: 'ChecklistCellTypeEmployee',
  components: {
    EmployeePicker
  },
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  data() {
    return {
      employeeData: null
    }
  },
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    classes() {
      return this.state.classes
    },
    disabled() {
      return this.state.disabled
    },
    isRequired() {
      return (this.cell.settings || {}).mandatory_field || false
    },
    employee: {
      get() {
        return this.employeeData || {}
      },
      set(value) {
        this.employeeData = value
        this.value = value && value.id ? value.id : 0
      }
    },
    value: {
      get() {
        return this.employee.id || 0
      },
      set(value) {
        const builder = this.data.ChecklistBuilder
        const index = this.checklist.index
        const previousValue = builder.getCellValue(index, this.cell, this.clone)
        builder.evalValues({
          index: index,
          dataEditor: this.cell,
          dataViewer: this.dataViewer,
          cloneIndex: this.clone,
          encode: false,
          saveCallback: undefined,
          ignoreAutoSaveSetting: false,
          // value: value,
          value: `${value || 0}`,
          previousValue: previousValue
        })
      }
    }
  },
  async mounted() {
    await this.loadEmployeeData()
    this.applySQLData()
  },
  methods: {
    async loadEmployeeData() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      let employeeID = builder.getCellValue(index, this.cell, this.clone, null)

      if (typeof employeeID === 'string') {
        employeeID = parseInt(employeeID.replace(/"/g, '&quot;'), 10)
      }

      if (!employeeID || employeeID <= 0) {
        employeeID = 0
      }

      const serviceOffline = await getService(
        'offline-data/employees',
        Connection.Offline
      )

      try {
        const result = await serviceOffline.get(employeeID)
        this.employeeData = result
      } catch (ex) {
        console.error(ex)

        if (employeeID > 0) {
          this.employeeData = { id: employeeID, inactive: false }
        } else {
          this.employeeData = null
        }
      }
    },
    applySQLData() {
      const builder = this.data.ChecklistBuilder
      if (typeof builder.applySQLData === 'function') {
        builder
          .applySQLData(this.checklist.index, this.cell, this.dataViewer)
          .then((result) => {
            if (
              !result ||
              typeof result !== 'object' ||
              !result.hasOwnProperty('value')
            ) {
              return
            }
            this.value(result.value)
          })
      }
    }
  }
}
</script>
